import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Button from '../../components/Button'
import { Column, Container, Row } from '../../components/Grid'

import api from '../../api'
import MainContext from '../../context/MainContext'
import util from '../../util'
import Backdrop from '../../components/Backdrop'

const Home = () => {

    const { authState, showLoading, setShowLoading, setLastLoginTimestamp } = useContext(MainContext)
    const [totalImages, setTotalImages] = useState()
    const [remainingImages, setRemainingImages] = useState()
    const [images, setImages] = useState()
    const [currentImage, setCurrentImage] = useState()
    const [lastClickedIndex, setLastClickedIndex] = useState(-1)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [showDetailedCard, setShowDetailedCard] = useState(false)
    const [isShiftDown, setIsShiftDown] = useState(false)
    const [userCurrentEstudo, setUserCurrentEstudo] = useState()

    // const initialImages = [
    //     { _id: 0, eye: 'OD', date: '01/01/2022', time: '08:00:00', src: mapExample, selected: false },
    // ]

    const infos = [
        { title: "ID do pac.", value: currentImage && currentImage._id.paciente_id },
        { title: "Idade", value: currentImage && `${currentImage.age} anos` },
        { title: "Olho", value: currentImage && currentImage.eye },
        { title: "Data", value: currentImage && currentImage._id.data },
        { title: "Hora", value: currentImage && currentImage._id.hora },
    ]

    useEffect(() => {
        setLastLoginTimestamp(Number(localStorage.getItem("loginTime")))
    })

    useEffect(() => {
        const onKeyDown = (event) => {
            const keyName = event.key

            if (keyName === "Shift")
                setIsShiftDown(true)
        }

        const onKeyUp = (event) => {
            const keyName = event.key

            if (keyName === "Shift")
                setIsShiftDown(false)
        }

        window.addEventListener('keydown', onKeyDown)
        window.addEventListener('keyup', onKeyUp)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
            window.removeEventListener('keyup', onKeyUp)
        }
    })

    const handleSelection = (el, index) => {
        const newImages = [...images]

        if (isShiftDown) {
            // If SHIFT is pressed
            for (let i = lastClickedIndex + 1; i <= index; i++) {
                const element = images[i];
                newImages.splice(i, 1, { ...element, selected: true })
            }
        }
        else {
            // SHIFT not pressed
            newImages.splice(index, 1, { ...el, selected: !el.selected })
        }

        // Store the last clicked index for multiple selection
        setLastClickedIndex(index)
        setImages(newImages)
    }

    const resetSelection = () => {
        setLastClickedIndex(-1)
    }

    const handleSend = async () => {
        setShowLoading(true)
        const response = images.map((el, index) => { return { _id: el._id, selected: el.selected } })
        await api.updateEstudoByUserId(userCurrentEstudo, authState.userInfo._id, 1, response)
            .catch((res) => {
                resetSelection()
                getEstudo(userCurrentEstudo)
                // TO-DO: Exibir mensagem de erro 

            })
            .then((res) => {
                resetSelection()
                getEstudo(userCurrentEstudo)
                // TO-DO: Exibir mensagem de sucesso
            })
    }

    const getEstudo = async (estudoNumber) => {
        await api.getEstudoByUserId(estudoNumber, authState.userInfo._id, 1)
            .then(res => {
                const order = [...res.data.respostas]
                setTotalImages(res.data.total)
                setRemainingImages(res.data.remaining)
                return order
            })
            .then(async (res) => {
                getRespostasImages(res)
            })
    }

    const getRespostasImages = async (respostas) => {
        var responses = []
        await Promise.all(respostas.map(async (resposta) => {
            const { data } = await api.getImageLMFOByDataHoraId(1, resposta._id.data, resposta._id.hora, resposta._id.paciente_id)
            const exame = await api.getExameByDataHoraId(resposta._id.data, resposta._id.hora, resposta._id.paciente_id)
            responses.push({
                ...resposta,
                LargeMapFullOriginal: data.imagem.base64,
                selected: false,
                age: Math.floor(Number(exame.data.exame[0]['Age (days)']) / 365),
                eye: exame.data.exame[0]['Exam Eye'],
            })
        }))
        setImages(responses)
        setShowLoading(false)
    }

    useEffect(() => {
        if (authState) {
            const currentEstudo = Number(authState.userInfo.settings.currentEstudo)
            setUserCurrentEstudo(currentEstudo)
            getEstudo(currentEstudo)
        } else {
            console.log('Erro:', 'AuthState não especificado.');
        }
    }, [])

    return (
        <>
            <Container>
                <Row>
                    <Column hideOnSmall hideOnMedium sm={12} md={2} lg={2}>
                        <CurrentImageCard>
                            <Row>
                                <Column sm={12} md={12} lg={12}>
                                    <h4>Informações</h4>
                                </Column>
                                <Column sm={12} md={12} lg={12}>
                                    <MapImage src={currentImage && currentImage.src} />
                                </Column>
                                {infos.map((info, index) => {
                                    return (
                                        <div key={index}>
                                            <Column sm={12} md={6} lg={6}>
                                                <span><b>{info.title}</b></span>
                                            </Column>
                                            <Column sm={12} md={6} lg={6}>
                                                <span>{info.value}</span>
                                            </Column>
                                        </div>
                                    )
                                })}
                            </Row>
                        </CurrentImageCard>
                    </Column>
                    <Column column sm={12} md={12} lg={10}>
                        <Row flex>
                            <Column flex column sm={12} md={3} lg={3}>
                                {userCurrentEstudo &&
                                    <Status>{`Estudo #${userCurrentEstudo}`}</Status>
                                }
                                <Status>{images && totalImages &&
                                    `1 - ${images.length} 
                                    de ${totalImages} imagens (${remainingImages > 0 ? remainingImages : 0} restantes)`}
                                </Status>
                            </Column>
                            <Column flex center sm={12} md={6} lg={6}>
                                <MainMessage>Selecione as imagens que considera <span style={{ color: 'red', fontWeight: 'bold' }}>ANORMAIS</span></MainMessage>
                            </Column>
                            <Column sm={12} md={3} lg={3}>
                                <Button onClick={handleSend}>Enviar</Button>
                            </Column>
                        </Row>
                        <Row>
                            {images && images.length > 0 ? images.map((el, index) => {
                                return (
                                    <Column
                                        key={'map-card-' + index}
                                        columns={10} sm={10} md={3} lg={2}
                                        onMouseEnter={() => {
                                            setCurrentImage(el)
                                            setCurrentIndex(index)
                                        }}>
                                        <MapCard
                                            selected={el.selected}
                                            isShiftSelected={(isShiftDown && index >= lastClickedIndex && index <= currentIndex)}
                                            onClick={() => handleSelection(el, index)}
                                            onContextMenu={() => {
                                                setCurrentImage(el)
                                                setShowDetailedCard(true)
                                            }}>
                                            <MapImage selected={el.selected} src={'data:image/jpeg;base64,' + el.LargeMapFullOriginal} />
                                        </MapCard>
                                    </Column>
                                )
                            }) :
                                <h4>Nenhuma imagem a ser exibida.</h4>
                            }
                        </Row>
                    </Column>
                </Row>
            </Container>
            <Backdrop show={showDetailedCard} onClick={() => setShowDetailedCard(false)}>
                <DatailedMapCard>
                    <MapImage src={currentImage && 'data:image/jpeg;base64,' + currentImage.LargeMapFullOriginal} />
                </DatailedMapCard>
            </Backdrop>
        </>
    )
}

const MapImage = styled.img`
    // Sem escala
    width: 208%;
    position: relative;
    left: 200%;
    margin-left: -275.5%;
    margin-top: -31.5%;
    margin-bottom: -2.5%;

    /* :hover {
        opacity: 0.5;
    } */

    ${({ selected }) => selected && css`
        opacity: 0.4;
    `}

    // non-selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-drag: none; /* Safari */
    -khtml-user-drag: none; /* Konqueror HTML */
    -moz-user-drag: none; /* Old versions of Firefox */
    -ms-user-drag: none; /* Internet Explorer/Edge */
`

const MapCard = styled.div`
    margin: 0.25rem;
    padding: .1rem;
    display: flex;
    text-align: center; /* ensures the image is always in the h-middle */
    overflow: hidden; /* hide the cropped portion */
    border: 2px solid white;

    :hover {
        /* background-color: blue; */
        opacity: 0.95;
        border-color: blue;
    }

    ${({ selected }) => selected && css`
        background-color: green;
        opacity: 0.75;
        border-color: green;
    `}

    ${({ isShiftSelected }) => isShiftSelected && css`
        background-color: blue;
        opacity: 0.75;
        border-color: blue;
    `}
    
`
const DatailedMapCard = styled(MapCard)`
    @media (orientation: landscape) {
        width: 47.5%;
    }

    @media (orientation: portrait) {
        width: 75%;
    }

    :hover {
        border-color: white;
        opacity: 1;
    }

    ${MapImage} {
        // Com escala
        width: 158%;
        position: relative;
        left: 190%;
        margin-left: -246.5%;
        margin-top: -5%;
        margin-bottom: -1.5%;
    }
`

const Status = styled.div`

`
const MainMessage = styled.div`
    font-size: 1.5rem;
`
const CurrentImageCard = styled.div`
    display: flex;
    margin: 1rem;
    padding: 1rem;
    background-color: lightgray;
`

export default Home