import React, { createContext } from 'react'

const MainContext = createContext(
    {
        isAuthenticated: {},
        setIsAuthenticated: function (isAuthenticated) {
            this.isAuthenticated = isAuthenticated
        },
        authState: {},
        setAuthState: function (authState) {
            this.authState = authState
        },
        authFetch: {},
        setAuthFetch: function (authFetch) {
            this.authFetch = authFetch
        },
    }
)

export const MainProvider = ({children, value}) => {

    return (
        <MainContext.Provider value={value}>
            <MainContext.Consumer>
                {(consumer) => {
                    return (
                        children
                    )
                }}
            </MainContext.Consumer>
        </MainContext.Provider>
    )
}

export default MainContext