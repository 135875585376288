import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate()

    const getVisitDuration = () => {
        const now = Date.now()
        const diff = now - Number(localStorage.getItem("loginTime"))
        const hour = Math.floor(diff / 1000 / 60 / 60)
        const min = Math.floor(diff / 1000 / 60 - hour * 60)
        const sec = Math.floor(diff / 1000 - min * 60 - hour * 3600)
        return `${String(hour).padStart(2, '0')}:${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`
    }

    useEffect(() => {
        console.log('logout');
        document.cookie = "loggedIn=false;"
        localStorage.setItem('loggedIn', 'false')
        localStorage.setItem('lastVisitDuration', getVisitDuration())
        navigate('/login')
    })

    return <h1>Logging out...</h1>
}

export default Logout