import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import wallpaper from '../../assets/login_wallpaper.jpg'
import logo from '../../assets/jtag_logo_black.svg'
import Button from "../../components/Button"
import api from "../../api"
import { Navigate, useNavigate } from "react-router-dom"
import MainContext from "../../context/MainContext"
import { useCookies } from "react-cookie"


const Login = () => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies([]);

    const { isAuthenticated, setIsAuthenticated, setAuthState, authState, setShowLoading } = useContext(MainContext)

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState('')

    useEffect(() => {
        setShowLoading(false)
    }, [])

    const handleSignIn = async () => {
        const credentials = {
            username,
            password,
        }

        await api.signin(credentials)
            .catch(err => {
                setLoginError(err.response.data.message)
            })
            .then(res => {
                const _authState = {
                    token: res.data.token,
                    userInfo: res.data.userInfo,
                    expiresAt: res.data.expiresAt,
                }
                setAuthState(_authState)
                setIsAuthenticated(true)
                localStorage.setItem('loggedIn', 'true')
                setCookie('loggedIn', true, { path: '/', maxAge: 600 })
                localStorage.setItem('loginTime', Date.now())
                navigate('/')
            })
    }

    useEffect(() => {
        const onPressEnter = (event) => {
            const keyName = event.key

            if (keyName === "Enter")
                handleSignIn()
        }

        window.addEventListener('keypress', onPressEnter)

        return () => {
            window.removeEventListener('keypress', onPressEnter)
        }
    })

    return (
        <>
            {/* {isAuthenticated && <Navigate to="/" />} */}
            <Container>
                <ImageWrapper>
                    <Wallpaper />
                </ImageWrapper>
                <LoginWrapper>
                    <Card>
                        <Logo src={logo} />
                        <TextInput type="text" placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)} />
                        <TextInput type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                        <Button color="darkblue" onClick={handleSignIn}>Login</Button>
                        {/* <SignUpMessage>Não possui uma conta? <a href="/signup">Cadastre-se</a></SignUpMessage> */}
                        <LoginError>{loginError}</LoginError>
                    </Card>
                </LoginWrapper>
            </Container>
        </>

    )
}

const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: lightgrey;
    overflow-y: hidden;
`
const LoginWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const ImageWrapper = styled.div`
    display: flex;
    flex: 2;

    background-color: #668499;
    background-image: url(${wallpaper});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media only screen and (max-width: 768px) {
        flex: 0;
    }
`
const Wallpaper = styled.div`
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6;
`
const Logo = styled.img`
    text-decoration: none;
    height: 5.5rem;
    margin: 2rem;
`
const Card = styled.div`
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    padding: 3rem;
    background-color: white;
    border-radius: 1rem;
    margin: 2rem;

    @media only screen and (max-width: 768px) {
        margin: 0;
        padding: 1rem;
    }
`
const TextInput = styled.input`
    margin: 1rem;
    padding: 0.75rem;
    display: flex;
    flex-grow: 1;
    font-size: 1.1rem;
    font-weight: 300;
    outline: none;

    border: none;
    border-bottom: 1px solid lightgray;
`
const SignUpMessage = styled.span`
    margin: 1rem 0.5rem;
    color: grey;
    
    a {
        font-weight: bold;
        color: blue;
        margin-left: 0.1rem;

        :hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }
`
const LoginError = styled.span`
    margin: 1rem 0.5rem;
    color: red;
    
    a {
        font-weight: bold;
        color: blue;
        margin-left: 0.1rem;

        :hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }
`
export default Login