import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate, useSearchParams } from 'react-router-dom'
import '@atlaskit/css-reset'
import styled from 'styled-components'
import Home from './views/Home'
import { MainProvider } from './context/MainContext'
import Login from './views/Login'
import MainLayout from './layouts/MainLayout'
import { CookiesProvider, useCookies } from 'react-cookie';

import Signup from './views/Signup'
import Classifier2 from './views/Classifier2'
import Logout from './views/Logout'
import Backdrop from './components/Backdrop'
import Classifier0 from './views/Classifier0'
import Stats from './views/Stats'

// Pallete
// primary: #003355
// secondary: #006655

const App = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authState, setAuthState] = useState()
  const [showLoading, setShowLoading] = useState(true)
  const [lastLoginTimestamp, setLastLoginTimestamp] = useState(Number(localStorage.getItem("loginTime")))
  const [loggedInTimeString, setLoggedInTimeString] = useState("")
  const [lastVisitDuration, setLastVisitDuration] = useState()

  const menuItems = isAuthenticated ?
    [
      { title: "1a. Etapa", path: "/" },
      { title: "2a. Etapa", path: "/classifier-2" },
    ]
    :
    [
      { title: "Sign In", path: "/login" },
    ]

  useEffect(() => {
    const preventRighClickDefault = (event) => {
      event.preventDefault();
    }

    document.addEventListener("contextmenu", preventRighClickDefault);

    return () => {
      document.removeEventListener("contextmenu", preventRighClickDefault)
    }
  }, [])

  // Timer
  useEffect(() => {
    const timer = setTimeout(() => {
      const now = Date.now()
      const diff = now - lastLoginTimestamp
      const hour = Math.floor(diff / 1000 / 60 / 60)
      const min = Math.floor(diff / 1000 / 60 - hour * 60)
      const sec = Math.floor(diff / 1000 - min * 60 - hour * 3600)
      setLoggedInTimeString(`${String(hour).padStart(2, '0')}:${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`)
    }, 1000);

    return () => clearTimeout(timer);
  });

  // Last visit duration
  useEffect(() => {
    const _lastVisitDuration = localStorage.getItem('lastVisitDuration')
    if (_lastVisitDuration) {
      setLastVisitDuration(_lastVisitDuration)
    }
  }, [])

  return (
    <MainProvider value={{
      isAuthenticated,
      setIsAuthenticated: isAuthenticated => setIsAuthenticated(isAuthenticated),
      authState,
      setAuthState: authState => setAuthState(authState),
      showLoading,
      setShowLoading: showLoading => setShowLoading(showLoading),
      loggedInTimeString,
      setLastLoginTimestamp: lastLoginTimestamp => setLastLoginTimestamp(lastLoginTimestamp),
      lastVisitDuration,
      setLastVisitDuration: lastVisitDuration => setLastVisitDuration(lastVisitDuration),
    }}>
      <CookiesProvider>
        <Background>
          {/* {Date(loggedInTime * 1000).slice(16, -34)} */}
          {/* {loggedInTimeString} */}
          <Router>
            <Routes>
              <Route path='/' element={<MainLayout menuItems={menuItems} />}>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/classifier-0" element={<Classifier0 />} />
                <Route exact path="/classifier-2" element={<Classifier2 />} />
                <Route exact path="/stats" element={<Stats />} />
              </Route>
              <Route exact path="/login" element={<Login />} />
              {/* <Route exact path="/signup" element={<Signup />} /> */}
              <Route exact path="/logout" element={<Logout />} />
              <Route path='*' element={<Navigate to='/login' />} />
            </Routes>
          </Router >
          <Backdrop show={showLoading} />
        </Background >
      </CookiesProvider>
    </MainProvider >
  )
}

const Background = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* background-color: lightgrey; */
  /* overflow-y: hidden; */
`

export default App;
