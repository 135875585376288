import React, { useContext, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import api from "../../api"
import Navbar from "../../components/Navbar"
import MainContext from "../../context/MainContext"

const MainLayout = ({ menuItems }) => {

  const navigate = useNavigate()
  const { setIsAuthenticated, authState, setAuthState } = useContext(MainContext)

  useEffect(() => {
    const retrieveAuthState = async () => {
      const userInfo = await api.getUser()
        .catch(res => {
          navigate('/logout')
        })
        .then(res => {
          console.log('MainLayout', res.data.userInfo);
          return res.data.userInfo
        })

      const adminValues = await api.getAdminValues()
        .catch(res => {
          navigate('/logout')
        })
        .then(res => {
          return res.data.adminValues
        })

      const _authState = {
        ...authState,
        userInfo,
        adminValues,
      }

      setAuthState(_authState)
    }
    
    if (localStorage.getItem('loggedIn') === 'true') {
      setIsAuthenticated(true)
      retrieveAuthState()
    } else {
      navigate('/login')
    }
  }, [])

  return (
    <Background>
      <Navbar menuItems={menuItems} />
      <Body>
        {authState ? <Outlet /> : <h1>Loading...</h1>}
      </Body>
    </Background>
  )
}

const Background = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`
const Body = styled.div`
  overflow-y: auto;
  height: 100%;
  padding-top: 1rem;
`
const Footer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
`
export default MainLayout