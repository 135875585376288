import axios from 'axios'

const publicFetch = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

// --- Login
export const signin = (payload) => publicFetch.post(`/signin`, payload)
export const signup = (payload) => publicFetch.post(`/signup`, payload)

// Users
export const getUser = () => publicFetch.get(`/user`)
export const getUsers = () => publicFetch.get(`/users`)

// Images
export const getImageLMFOByDataHoraId = (callall, data, hora, id) => publicFetch.get(`/imagem/lmfo/${callall}/${data}/${hora}/${id}`)
export const getImage4MRFOByDataHoraId = (callall, data, hora, id) => publicFetch.get(`/imagem/4mrfo/${callall}/${data}/${hora}/${id}`)

// Studies
export const getEstudoByUserId = (nEstudo, userId, nEtapa) => publicFetch.get(`/estudo/${nEstudo}/${userId}/etapa/${nEtapa}`)
export const updateEstudoByUserId = (nEstudo, userId, nEtapa, payload) => publicFetch.post(`/estudo/${nEstudo}/${userId}/etapa/${nEtapa}/update`, payload)

// Visitas
export const getVisitaByDataHoraId = (data, hora, id) => publicFetch.get(`/visita/${data}/${hora}/${id}`)

// Exames
export const getExameByDataHoraId = (data, hora, id) => publicFetch.get(`/exame/min/${data}/${hora}/${id}`)

// Classes
export const getClasses = () => publicFetch.get(`/classes`)

// Estudos
export const getEstudos = () => publicFetch.get(`/estudos`)

// Admin
export const getAdminValues = () => publicFetch.get(`/admin-values`)

const api = {
    signin,
    signup,
    getUser,
    getUsers,
    getImageLMFOByDataHoraId,
    getImage4MRFOByDataHoraId,
    getEstudoByUserId,
    updateEstudoByUserId,
    getVisitaByDataHoraId,
    getExameByDataHoraId,
    getClasses,
    getEstudos,
    getAdminValues,
}

export default api