import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Button from '../../components/Button'
import { Column, Container, Row } from '../../components/Grid'

import mapExample from '../../assets/3_OD_01092016_132643_4MapsRefrFullOriginal.png'
import thumbnail from '../../assets/3_OD_12112015_085134_Large-Map.png'
import MainContext from '../../context/MainContext'
import api from '../../api'
import util from '../../util'

const Classifier2 = () => {

    const { isAuthenticated, authState, showLoading, setShowLoading } = useContext(MainContext)

    // const initialImages = [
    //     { id: 0, eye: 'OD', date: '01/01/2022', time: '08:00:00', src: mapExample, thumbnail: thumbnail, selected: false, ready: false, type: "" },
    // ]

    const [images, setImages] = useState()
    const [totalImages, setTotalImages] = useState()
    const [remainingImages, setRemainingImages] = useState()
    const [currentImage, setCurrentImage] = useState()
    // const [showAll, setShowAll] = useState(true)
    const [selectionArray, setSelectionArray] = useState([])
    const [estudo1CurrentPage, setEstudo1CurrentPage] = useState(0)
    const [estudo1Pagination, setEstudo1Pagination] = useState()
    const [classes, setClasses] = useState()
    const [userCurrentEstudo, setUserCurrentEstudo] = useState()

    const infos = [
        { title: "ID", value: currentImage && currentImage._id.paciente_id },
        { title: "Idade", value: currentImage && `${currentImage.age} anos` },
        { title: "Olho", value: currentImage && currentImage.eye },
        { title: "Data", value: currentImage && currentImage._id.data },
        { title: "Hora", value: currentImage && currentImage._id.hora },
    ]

    useEffect(() => {
        setShowLoading(true)
    }, [])

    const getClasses = async () => {
        await api.getClasses()
            .then(res => {
                setClasses(res.data.classes)
            })
    }

    useEffect(() => {
        getClasses()
    }, [])

    const handleSelection = (el, index) => {
        if (el) {
            setSelectionArray([el._id])
            setCurrentImage({
                ...el,
                selected: true,
            })
        }
    }

    const handleRightClick = (el, index) => {
        const newSelectionArray = [...selectionArray]
        setCurrentImage({
            ...el,
            selected: true,
        })

        if (newSelectionArray.includes(el._id)) {
            newSelectionArray.splice(newSelectionArray.findIndex(value => value === el._id), 1)
        }
        else {
            newSelectionArray.push(el._id)
        }
        setSelectionArray(newSelectionArray)
    }

    const handleSend = async () => {
        if (!images.map(el => el.ready).includes(false)) {
            setShowLoading(true)
            const response = images.map((el, index) => { return { _id: el._id, classe: el.classe } })
            await api.updateEstudoByUserId(userCurrentEstudo, authState.userInfo._id, 2, response)
                .catch(async (res) => {
                    await getEstudo(userCurrentEstudo)
                    // TO-DO: Exibir mensagem de erro 

                })
                .then(async (res) => {
                    console.log(res)
                    await getEstudo(userCurrentEstudo)
                    // TO-DO: Exibir mensagem de sucesso
                })
            // setImages()
        }
        else {
            console.log("Todos os exames devem ser classificados!");
        }
    }

    const handleClassification = (classe) => {
        const _images = [...images]

        selectionArray.forEach(value => {
            const index = _images.findIndex(el => el._id === value)
            const newImage = {
                ...images[index],
                classe: classe,
                ready: true,
            }
            _images.splice(index, 1, newImage)
            setCurrentImage(newImage)

        });
        setImages(_images)
    }

    const getEstudo = async (estudoNumber) => {
        await api.getEstudoByUserId(estudoNumber, authState.userInfo._id, 2)
            .then(res => {
                setTotalImages(res.data.total)
                setRemainingImages(res.data.remaining)
                return res.data.respostas
            })
            .then(async (res) => {
                getRespostasImages(res)
            })
    }

    const getRespostasImages = async (respostas) => {
        var responses = []
        if (respostas) {
            await Promise.all(respostas.map(async (resposta) => {
                const mrfo = await api.getImage4MRFOByDataHoraId(2, resposta._id.data, resposta._id.hora, resposta._id.paciente_id)
                const lmfo = await api.getImageLMFOByDataHoraId(1, resposta._id.data, resposta._id.hora, resposta._id.paciente_id)
                const exame = await api.getExameByDataHoraId(resposta._id.data, resposta._id.hora, resposta._id.paciente_id)
                responses.push({
                    ...resposta,
                    src: 'data:image/jpeg;base64,' + mrfo.data.imagem.base64,
                    thumbnail: 'data:image/jpeg;base64,' + lmfo.data.imagem.base64,
                    selected: false,
                    age: Math.floor(Number(exame.data.exame[0]['Age (days)']) / 365),
                    eye: exame.data.exame[0]['Exam Eye'],
                    ready: false,
                })
            }))
            setImages(responses)
            setShowLoading(false)
            handleSelection(responses[0], 0)
        }
    }

    useEffect(() => {
        if (authState) {
            const currentEstudo = Number(authState.userInfo.settings.currentEstudo)
            setUserCurrentEstudo(currentEstudo)
            getEstudo(currentEstudo)
        } else {
            console.log('Erro:', 'AuthState não especificado.');
        }
    }, [])

    return (
        // <Container>
        <Row>
            <Column sm={12} md={9} lg={9}>
                <CurrentImageCard>
                    <Row>
                        <Column sm={12} md={9} lg={9}>
                            <h4>Classificação</h4>
                        </Column>
                        <Column sm={12} md={9} lg={9}>
                            <MapImageFull src={currentImage && currentImage.src} />
                        </Column>
                        <Column sm={12} md={3} lg={3}>
                            {classes && classes.map((classe, index) => {
                                return (
                                    <Column key={index} sm={6} md={12} lg={12}>
                                        <Button
                                            noMargin
                                            color={currentImage && currentImage.classe && currentImage.classe === classe.nome ? '#006655' : 'white'}
                                            textColor={currentImage && currentImage.classe && currentImage.classe === classe.nome ? 'white' : 'black'}
                                            onClick={() => handleClassification(classe.nome)}
                                        >
                                            {classe.titulo}
                                        </Button>
                                    </Column>
                                )
                            })}
                        </Column>
                        <Column sm={12} md={9} lg={9}>
                            {infos.map((info, index) => {
                                return (
                                    <div key={index}>
                                        <Column sm={6} md={1} lg={1}>
                                            <span><b>{info.title}</b></span>
                                        </Column>
                                        <Column sm={6} md={3} lg={3}>
                                            <span>{info.value}</span>
                                        </Column>
                                    </div>
                                )
                            })}
                        </Column>
                    </Row>
                </CurrentImageCard>

            </Column>
            <Column column sm={12} md={3} lg={3}>
                <Row flex>
                    <Column flex column sm={12} md={9} lg={9}>
                        {userCurrentEstudo &&
                            <Status>{`Estudo #${userCurrentEstudo}`}</Status>
                        }
                        <Status>{images && totalImages &&
                            `${images.length > 0 ? `1 - ${images.length}` : '0'} 
                                de ${totalImages} imagens (${remainingImages > 0 ? remainingImages : 0} restantes)`}
                        </Status>
                        {/* <input id={"checkbox-showall"} type={"checkbox"} checked={showAll} onChange={() => setShowAll(prev => !prev)}/>
                            <label for={"checkbox-showall"}>Mostrar todos</label> */}
                    </Column>
                    <Column sm={12} md={3} lg={3}>
                        <Button disabled={images && (images.map(el => el.ready).includes(false) || images.length < 1)} onClick={handleSend}>Enviar</Button>
                    </Column>
                </Row>
                <Row>
                    {images && images.length > 0 ? images.map((el, index) => {
                        return (
                            <Column key={'map-card-' + index} columns={12} sm={12} md={4} lg={4}>
                                <MapCard
                                    ready={el.ready}
                                    selected={selectionArray && currentImage && (selectionArray.length > 0 ? selectionArray.includes(el._id) : el._id === currentImage._id)}
                                    onClick={() => handleSelection(el, index)}
                                    onContextMenu={() => handleRightClick(el, index)}
                                >
                                    <MapImage
                                        ready={el.ready}
                                        selected={selectionArray && currentImage && (selectionArray.length > 0 ? selectionArray.includes(el._id) : el._id === currentImage._id)}
                                        src={el.thumbnail}
                                    />
                                </MapCard>
                            </Column>
                        )
                    }) :
                        <h4>Nenhuma imagem a ser exibida.</h4>}
                </Row>
            </Column>
        </Row>
        // </Container>
    )
}

const MapCard = styled.div`
    margin: 0.25rem;
    padding: .1rem;
    display: flex;
    text-align: center; /* ensures the image is always in the h-middle */
    overflow: hidden; /* hide the cropped portion */
    border: 2px solid white;

    :hover {
        /* background-color: blue; */
        opacity: 0.95;
        border-color: blue;
    }

    ${({ selected }) => selected && css`
        background-color: blue;
        opacity: 0.75;
        border-color: blue;
    `}

    ${({ ready }) => ready && css`
        background-color: green;
        opacity: 1;
    `}
`
const MapImage = styled.img`
    // Com escala
    /* width: 155%;
    position: relative;
    left: 100%;
    margin-left: -255%;
    margin-top: -4.8%; */

    // Sem escala
    width: 208%;
    position: relative;
    left: 200%;
    margin-left: -275.5%;
    margin-top: -31.5%;
    margin-bottom: -2.5%;

    /* :hover {
        opacity: 0.5;
    } */

    ${({ ready }) => ready && css`
        opacity: 0.4;
    `}

    ${({ selected }) => selected && css`
        opacity: 0.4;
    `}

    // non-selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-drag: none; /* Safari */
    -khtml-user-drag: none; /* Konqueror HTML */
    -moz-user-drag: none; /* Old versions of Firefox */
    -ms-user-drag: none; /* Internet Explorer/Edge */
`
const MapImageFull = styled.img`
    width: 100%;
    object-fit: cover;
    padding: 0;
    margin: 0;

    ${({ ready }) => ready && css`
        opacity: 0.7;
    `}
    
    ${({ selected }) => selected && css`
        opacity: 0.5;
    `}

    // non-selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-drag: none; /* Safari */
    -khtml-user-drag: none; /* Konqueror HTML */
    -moz-user-drag: none; /* Old versions of Firefox */
    -ms-user-drag: none; /* Internet Explorer/Edge */
`
const Status = styled.div`

`
const CurrentImageCard = styled.div`
    display: flex;
    margin: 1rem;
    padding: 1rem;
    background-color: lightgray;
`

export default Classifier2