import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import api from '../../api'
import { Column, Container, Row } from '../../components/Grid'
import MainContext from '../../context/MainContext'

const Stats = () => {

    const { setShowLoading } = useContext(MainContext)

    const [users, setUsers] = useState()
    const [estudos, setEstudos] = useState()
    const [adminValues, setAdminValues] = useState()

    const getUsers = async () => {
        const { data } = await api.getUsers()
        setUsers(data.users)
    }

    const getEstudos = async () => {
        const { data } = await api.getEstudos()
        setEstudos(data.estudos)
    }

    const getAdminValues = async () => {
        const { data } = await api.getAdminValues()
        setAdminValues(data.adminValues)
    }

    useEffect(() => {
        setShowLoading(false)
        getUsers()
        getEstudos()
        getAdminValues()
    }, [])

    return (
        <Container>
            {/* {users &&
                users.map((user, index) => (
                    <div key={index}>{user.firstName}</div>
                ))} */}

            <Row>
                <Column sm='6' md='6' lg='2'>
                    Estudos
                    {estudos &&
                        estudos.map((estudo, index) => (
                            <Estudo key={index}>
                                <div>{estudo._id}</div>
                                <div>{estudo.nome}</div>
                                <div>{estudo.descricao}</div>
                            </Estudo>
                        ))}
                </Column>
                <Column sm='6' md='6' lg='2'>
                    Configuração atual
                    {adminValues &&
                        Object.values(adminValues).map((value, index) => (
                            <Estudo key={index}>
                                <div>{value.titulo}</div>
                                <div>{value.valor}</div>
                            </Estudo>
                        ))}
                </Column>
            </Row>
        </Container>
    )
}

const Estudo = ({ children, style }) => {

    return (
        <Card>
            {children}
        </Card>
    )
}

const Card = styled.div`
        margin: 1rem 0rem;
        padding: 1rem;
        background-color: lightgrey;
        text-align: justify;
    `

export default Stats