import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import jtagLogo from '../../assets/jtag_logo.svg'
import agrioLogo from '../../assets/agrio_logo.svg'
import MainContext from '../../context/MainContext'
import { useLocation } from 'react-router-dom'

// Icons
import { Menu as MenuIcon } from '@styled-icons/ionicons-sharp/Menu'
import Icon from '../Icon'
import { LogOut } from '@styled-icons/ionicons-sharp/LogOut'
import { StatsChart } from '@styled-icons/ionicons-sharp/StatsChart'


const Navbar = ({ menuItems }) => {

    const { authState, loggedInTimeString, lastVisitDuration } = useContext(MainContext)
    const location = useLocation()

    const [isOpen, setIsOpen] = useState(false)

    return (
        <Nav>
            <NavContainer>
                <LogoContainer>
                    <Logo src={jtagLogo}></Logo>
                </LogoContainer>
                <Timer>
                    {lastVisitDuration && <LastVisit>{`(${lastVisitDuration})`}</LastVisit>}
                    {loggedInTimeString}
                </Timer>
                <Hamburger color="white" onClick={() => setIsOpen(prev => !prev)} />
                <Menu isOpen={isOpen}>
                    {/* Hardcoded: somente WChamon e Felipe usarão. TO-DO: criar opção no settings */}
                    {authState &&
                        (authState.userInfo.role === "admin" || authState.userInfo.role === "manager") &&
                        <MenuLink href={"/classifier-0"} current={"/classifier-0" === location.pathname}>Etapa preliminar</MenuLink>
                    }
                    {menuItems.map((item, index) => {
                        return <MenuLink key={index} href={item.path} current={item.path === location.pathname}>{
                            item.icon && <Icon key={index} href={item.path} color="white" badgeNumber={item.badgeNumber}>{item.icon}</Icon>
                        }
                            {item.title}
                        </MenuLink>
                    })}
                    {authState &&
                        (authState.userInfo.role === "admin") &&
                        <MenuLink href={'/stats'}>
                            <Icon href={'/stats'} color={"/stats" === location.pathname ? 'seagreen' : 'white'}><StatsChart /></Icon>
                        </MenuLink>
                    }
                    <MenuLink href={'/logout'}>
                        <Icon href={'/logout'} color="white"><LogOut /></Icon>
                    </MenuLink>
                </Menu>
            </NavContainer>
        </Nav>
    )
}

const Nav = styled.div`
    padding: 0 0rem;
    background: #003355;
`

const NavContainer = styled.div`
    max-width: 1600px;
    padding: 0 2rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media only screen and (max-width: 768px) {
        padding: 0 0;
    }
`

const Menu = styled.div`
    display: none;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 768px) {
        display: flex;
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        max-height: ${({ isOpen }) => (isOpen ? "100vh" : "0")};
        transition: max-height 0.3s ease-in;
    }

    @media (min-width: 768px) {
        display: flex;
        overflow: hidden;
        flex-direction: row;
    }
`

const MenuLink = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2rem;
    color: white;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: all 0.15s ease-in;
    font-size: 0.9rem;
    font-weight: 400;

    &:hover {
        color: grey;
        text-decoration: none;
    }

    ${({ current }) => current && css`
        color: seagreen;
        font-weight: 700;
        pointer-events: none;
    `}
`

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const Logo = styled.img`
    padding: 1rem 0;
    padding-left: 2rem;
    text-decoration: none;
    font-weight: 800;
    font-size: 1.7rem;
    height: 2.5rem;
    cursor: pointer;
`

const Hamburger = styled(MenuIcon)`
    font-size: 16px;
    width: 2rem;
    cursor: pointer;
    display: none;
    padding-right: 2rem;
    color: white;


    @media (max-width: 768px) {
        display: flex;
    }
`
const Toolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 2rem;

    @media (max-width: 768px) {
        width: 100%;
        overflow: hidden;
        padding: 0.5rem 0;
        justify-content: center;
        background-image: linear-gradient(to bottom, lightgrey, white);
    }
`
// const ToolbarIcon = styled(Icon)`
//     @media only screen and (max-width: 768px) {
//         width: 2.0rem;
//         color: #003355;
//         &:hover {
//             background: rgba(255, 255, 255, 0);
//             color: ${({ color }) => color}
//         }
//     } 
// `
const Initials = styled.div`
    width:  2.5rem;
    height: 2.5rem;
    border-radius: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ color }) => color ? color : 'lightgrey'};

    // non-selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`
const Timer = styled.div`
    font-size: 1.8rem;
    color: white;
    flex-grow: 1;
    text-align: end;
    margin: 0rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`
const LastVisit = styled.div`
    font-size: 1.0rem;
    color: grey;
    flex-grow: 1;
    text-align: end;
    margin: 0rem 1rem;
`

export default Navbar;
