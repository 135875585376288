import styled, { keyframes } from 'styled-components'

const Backdrop = ({ show, children, onClick }) => {

    return (
        <BackdropWrapper show={show} onClick={onClick} >
            {children ?
                children :
                <>
                    <Dot delay="0s" />
                    <Dot delay="0.125s" />
                    <Dot delay="0.25s" />
                </>
            }
        </BackdropWrapper>
    )
}

const BounceAnimation = keyframes`
    0% { 
        opacity: 1; 
    }

    50% { 
        opacity: 0.4;
    }

    100% { 
        opacity: 1;
    }
`

const BackdropWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;

    ${({ show }) => !show && `display: none;`}
`

const Dot = styled.div`
    background-color: #003355;
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    margin: 0 0.25rem;
    /*Animation*/
    animation: ${BounceAnimation} 1s ease-in-out infinite;
    animation-delay: ${(props) => props.delay};
`

export default Backdrop