import React from 'react'
import styled, { css } from 'styled-components'

const Button = ({ color, textColor, children, onClick, round = false, disabled = false, noMargin = false }) => {
    
    const handleClick = (e) => {
        if (onClick)
            onClick(e)
        e.target.blur()
    }

    return (
        // <Container >
            <Background color={color} textColor={textColor} round={round} disabled={disabled} noMargin={noMargin} onClick={(e) => handleClick(e)} >
                <span>{children}</span>
            </Background>
        // </Container>
    )
}

const Container = styled.div`
    max-width: 10rem;
    background-color: white;
    flex: 1;
    margin: 0.25rem;
`

const Background = styled.div`
    padding: 0.35rem 1rem;
    border-radius: 0.25rem;
    /* max-width: 10rem; */
    flex-grow: 1;
    margin: ${({noMargin}) => noMargin ? '0rem' : '0.5rem'};
    padding: 0.5rem;

    background-color: green;

    display: flex;
    justify-content: center;
    align-items: center;

    ${({ color }) => color && `background-color: ${color};`}

    span {
        text-transform: uppercase;
        color: ${({textColor}) => textColor ? textColor : 'white'};
        font-weight: 500;
    }

    &:hover {
        opacity: 0.76;
        cursor: pointer;
    }

    &:active {
        opacity: 0.88;
    }

    ${({round}) => round && `
        width: 1rem;
        height: 1rem;
        border-radius: 1rem;
    `}

    ${({disabled}) => disabled && css`
        pointer-events: none;
        background-color: grey;
    `}

    // non-selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`

export default Button;