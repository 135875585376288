import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import wallpaper from '../../assets/login_wallpaper.jpg'
import logo from '../../assets/jtag_logo_black.svg'
import Button from "../../components/Button"
import api from "../../api"
import { Navigate } from "react-router-dom"
import MainContext from "../../context/MainContext"

const Signup = () => {

    const { isAuthenticated, setIsAuthenticated, setAuthState, setShowLoading } = useContext(MainContext)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        setShowLoading(false)
    }, [])

    const handleSignUp = async (event) => {
        const credentials = {
            username,
            password,
            firstName,
            lastName,
            email
        }

        await api.signup(credentials)
            .catch(err => console.log(err))
            .then(res => {
                const _authState = {
                    token: res.data.token,
                    userInfo: res.data.userInfo,
                    expiresAt: res.data.expiresAt,
                }
                setAuthState(_authState)
                setIsAuthenticated(true)
            })
    }

    return (
        <Container>
            {isAuthenticated && <Navigate to="/" />}
            <ImageWrapper>
                <Wallpaper />
            </ImageWrapper>
            <LoginWrapper>
                <Card>
                    <Logo src={logo} />
                    <TextInput type="text" placeholder="Nome" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                    <TextInput type="text" placeholder="Sobrenome" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                    <TextInput type="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
                    <TextInput type="text" placeholder="Username" value={username} onChange={(event) => setUsername(event.target.value)} />
                    <TextInput type="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                    <Button color="darkblue" onClick={handleSignUp}>Cadastrar</Button>
                </Card>
            </LoginWrapper>
        </Container>
    )
}

const Container = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: lightgrey;
    overflow-y: hidden;
`
const LoginWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const ImageWrapper = styled.div`
    display: flex;
    flex: 2;

    background-color: #668499;
    background-image: url(${wallpaper});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`
const Wallpaper = styled.div`
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6;
`
const Logo = styled.img`
    text-decoration: none;
    height: 5.5rem;
    margin: 2rem;
`
const Card = styled.div`
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    padding: 3rem;
    background-color: white;
    border-radius: 1rem;
`
const TextInput = styled.input`
    margin: 1rem;
    padding: 0.75rem;
    display: flex;
    flex-grow: 1;
    font-size: 1.1rem;
    font-weight: 300;
    outline: none;

    border: none;
    border-bottom: 1px solid lightgray;
`

export default Signup